import { ref } from 'vue'
import { defineStore } from 'pinia'

//保存当前的语言
export default defineStore('lang', () => {
    const currentLang = ref(getLang())

    function setLang(lang) { //设置语言
        localStorage.setItem('lang', lang);
        currentLang.value = lang
    }

    function getLang() { //获取当前语言
        return localStorage.getItem('lang') || 'en';
    }

    return {
        currentLang,
        setLang,
        getLang
    }
})