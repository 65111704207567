<template>
    <div style="background: #000000;height: auto;min-height: 100vh;">
      <TopNavigation></TopNavigation>
      <div class="login-box">
        <div></div>
        <div class="input-box">
          <div  style="margin-top: 100px;">
            <span class="title">{{ t('registration.title') }}</span>
            <div class="login-tips">
              {{t('registration.registrationTips')}}
              <span  @click="router.push('/login')" style="color: var(--theme-color);cursor: pointer">{{t('headNavigation.login')}}</span>
            </div>
              <a-form @submit-success="submitRegistration" :rules="rules" ref="formRef" :model="form" size="medium">
                <span class="form-label">{{t('registration.nickname')}}</span>
                <a-form-item  class="input"  hide-asterisk hide-label validate-trigger="blur">
                  <a-input  v-model="form.nickname" :placeholder="t('registration.nicknameTip')">
                    <template #prefix>
                      <icon-user />
                    </template>
                  </a-input>
                </a-form-item>

                <span class="form-label">{{t('registration.invite')}}</span>
                <a-form-item  class="input"  hide-asterisk hide-label field="invite">
                  <a-input v-model="form.invite" :placeholder="t('registration.invitedTip')">
                    <template #prefix>
                      <icon-safe />
                    </template>
                  </a-input>
                  <template #extra>
                    <div>{{t('registration.sendTips')}}</div>
                  </template>
                </a-form-item>

                <span class="form-label">{{t('registration.email')}}</span>
                <a-form-item   class="input"  hide-asterisk hide-label field="email" validate-trigger="blur">
                  <a-input v-model="form.email" :placeholder="t('registration.emailTip')">
                    <template #prefix>
                      <icon-email />
                    </template>
                  </a-input>
                </a-form-item>
                <span class="form-label">{{t('registration.phoneVerificationCode')}}</span>
                <a-form-item  class="input"   hide-asterisk hide-label field="content" validate-trigger="blur">
                  <a-input v-model="form.content" :placeholder="t('registration.phoneVerificationCodeError')">
                    <template #prefix>
                      <icon-send />
                    </template>
                    <template #append>
                      <a-button type="text"  :loading="codeLoading"  :disabled="isDisabled" @click="sendCode">
                        {{ countdown }}
                      </a-button>
                    </template>
                  </a-input>
                </a-form-item>

                <span class="form-label">{{t('registration.password')}}</span>
                <a-form-item  class="input"  hide-asterisk hide-label field="password" validate-trigger="blur">
                  <a-input-password v-model="form.password" :placeholder="t('registration.passowrdTip')">
                    <template #prefix>
                      <icon-safe />
                    </template>
                  </a-input-password>
                </a-form-item>

                <span class="form-label">{{t('registration.confirmPassword')}}</span>
                <a-form-item   class="input"  hide-asterisk hide-label field="confirmPassword" validate-trigger="blur">
                  <a-input-password v-model="form.confirmPassword" :placeholder="t('registration.confirmPasswordTip')">
                    <template #prefix>
                      <icon-safe />
                    </template>
                  </a-input-password>
                </a-form-item>


                <a-form-item hide-asterisk hide-label  class="input" >
                  <a-button class="login-btn" type="primary" long html-type="submit">{{
                      t('registration.registration') }}</a-button>
                </a-form-item>
              </a-form>

          </div>
        </div>
      </div>
    </div>


</template>

<script setup>
import { Notification } from '@arco-design/web-vue';
import { useRouter } from 'vue-router';
import {reactive, ref, onBeforeUnmount, computed, watch} from 'vue'
import { useI18n} from 'vue-i18n'
import { insertRegister, sendVerificationCode } from "@/api/user/user";
import { verifyEmail } from "@/uilts/text";
import TopNavigation from "@/components/navigation/TopNavigation.vue";
const { t ,locale } = useI18n()
const router = useRouter();
const form = reactive({
  email: '',
  password: '',
  confirmPassword: '',
  content: '',
  nickname: '',
  mobile: '',
  invite: '',
});




const countdown = ref(t('registration.snedMessage'))
const timer = ref(null)
const isDisabled = ref(false)


const codeLoading =ref(false)
function sendCode() { //发送验证码=

  if (form.invite.length<3)
  {
    formRef.value.validateField('invite')
    return
  }
  if (form.email.length<3)
  {
    formRef.value.validateField('email')
    return
  }
  if (!verifyEmail(form.email)) {
    Notification.warning({
      title: t('registration.formatError')
    })
    return
  }
  const state =t('registration.snedMessage')
  if (countdown.value == state) {
    isDisabled.value = true;
    codeLoading.value=true
    sendVerificationCode({ email: form.email,mold:'10',invite:form.invite }).then(r => {
      countdown.value = 60;
      codeLoading.value=false
      Notification.success({
        title: t('registration.codeSucess')
      })
      timer.value = setInterval(() => {
        countdown.value--;
        if (countdown.value === 0) {
          clearInterval(timer.value);
          countdown.value = state
          isDisabled.value = false;
        }
      }, 1000);
    }).catch(()=>{
      codeLoading.value=false
      clearInterval(timer.value)
      countdown.value = state
      isDisabled.value = false
    })


  }
}


function submitRegistration() {
  insertRegister(form).then(r => {
    Notification.success({
      title: t('registration.sucess')
    })
    router.replace('/login')
  })
}

const formRef =ref(null)
watch(locale,()=>{
  formRef.value.clearValidate()
  countdown.value = t('registration.snedMessage');
})


let rules = reactive({
  nickname: [
    {
      required: true,
      message: computed(() => t('registration.nicknameTip'))
    }
  ],
  email: [
    {
      type: 'email',
      required: true,
      message:  computed(() =>(t('registration.emailTip')))
    }
  ],
  password: [
    {
      required: true,
      message: computed(() => t('registration.passowrdTip'))
    },
  ],
  confirmPassword: [
    {
      required: true,
      message:  computed(() =>t('registration.confirmPasswordTip'))
    },
    {
      validator: (value, cb) => {
        if (value !== form.password) {
          cb( computed(() =>t('registration.atypism')))
        } else {
          cb()
        }
      }
    }
  ],
  content: [
    {
      required: true,
      message: computed(() => t('registration.phoneVerificationCodeError'))
    },
  ],
  invite: [
    {
      required: true,
      message:  computed(() =>t('registration.invitedTip')),
      minLength:3
    },
  ],
}
)

onBeforeUnmount(() => {
  if (timer.value) {
    clearInterval(timer.value);
  }
})

</script>
<style scoped lang="less">

.login-box{
  min-height: 100vh;
  background-image: url("../../assets/img/login_cover.png");
  background-repeat: no-repeat;
  background-size: 75% ;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.input-box{
  width: 380px;
  padding: 20px;
  height: 100%;
  margin-right: 30px;
  z-index: 2;
  .title{
    color: #FFFFFF;
    font-size: 30px;
  }
  h1{
    color: #FFFFFF;
    font-size: 40px;
  }
  .login-tips{
    color: #c1c1c1;
    font-weight: 700;
    font-size: 15px;
    margin: 15px 3px;
    line-height: 25px;
  }
  .login-btn{
    border-radius: 32px 32px 32px 32px;
    height: 53px;
    background: #FF9047;
  }
  .form-label{
    color: #dedede;
    padding: 10px 0;
    margin-left: 8px;
  }

}


.input{
  margin-bottom:25px;
  ::v-deep .arco-input-wrapper{
    border: none;
    border-radius: 0px;
    border-bottom: 2px solid var(--thenme-font-color);
    color: #f2f3f5;
    .arco-input.arco-input-size-medium{
      font-size: 16px;
    }

  }
  ::v-deep .arco-input-wrapper:hover{
    background-color: transparent;
  }
  ::v-deep .arco-input-focus{
    background-color: transparent;
  }


}
::v-deep .arco-form-item-status-error .arco-input-wrapper:not(.arco-input-disabled), .arco-form-item-status-error .arco-textarea-wrapper:not(.arco-textarea-disabled){
  background-color: transparent;
}

.bottom-title{
  color: rgba(242,243,245,0.7);
  position: fixed;
  bottom: 10px;
  left: 40%;
  transform: translateX(-50%);
  z-index: -1;
}

::v-deep .arco-input-append{
  background: #0F0F0F;
  border-radius: 12px;
  border: none;
  padding: 0;

}

::v-deep .arco-btn-text:hover, .arco-btn-text[type='button']:hover, .arco-btn-text[type='submit']:hover{
  background-color: transparent;
}

</style>
