import { createI18n } from 'vue-i18n'
//国际化语言
import zh from '@/lang/zh'
import en from '@/lang/en'
export default new createI18n({
    legacy: false,//组合式API
    locale: 'en',//当前语言
    fallbackLocale: 'zh',
    messages: { //语言包
        zh,
        en
    }

})