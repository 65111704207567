import userUser from '@/store/modules/user'
import userLang from '@/store/modules/lang'
import { TOKEN_NAME } from '@/config/site'

export function getToken() {
    const userStore = userUser()
    return userStore.token
}//获取store里的token


export function updateUserMessage() {
    const userStore = userUser()
    userStore.updateUserMessage()
}//更新用户信息

export function setToken(token) {

}

//设置本地里的token
export function setSessionToken(token) {
    sessionStorage.setItem(TOKEN_NAME, token)
}

//获取本地里的token
export function getSessionToken() {
    return sessionStorage.getItem(TOKEN_NAME)
}

//移除本地token
export function removeSessionToken() {
    sessionStorage.removeItem(TOKEN_NAME);

}

//删除登录信息 
export function removeLoginState() {
    removeSessionToken()
    const userStore = userUser()
    userStore.removeUser()
}

//获取用户信息
export function getUserMessage() {
    const userStore = userUser()
    return userStore.userMsg
}

export function getUserLang() { //获取用户使用的语言
    const langStore = userLang()
    return langStore.getLang()
}