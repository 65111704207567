<template >
  <a-config-provider :locale="locales[langStore.currentLang]">
    <RouterView></RouterView>
  </a-config-provider>
</template>

<script setup>
import zhCN from '@arco-design/web-vue/es/locale/lang/zh-tw';
import enUS from '@arco-design/web-vue/es/locale/lang/en-us';
import useLang from '@/store/modules/lang'
import { useI18n } from 'vue-i18n'
const { locale } = useI18n()
const langStore = useLang()
const locales = {
  'zh': zhCN,
  'en': enUS,
};
locale.value = langStore.currentLang


</script>

<style></style>
