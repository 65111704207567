import useClipboard from "vue-clipboard3";
const { toClipboard } = useClipboard();

 export  function  textCopy(text){ //文本到粘贴板
    toClipboard(text);
}
export  function  verifyEmail(email){  //验证是否邮箱
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}



export  function removeEmptyText(obj) {
    Object.keys(obj).forEach(key => {
        if (typeof obj[key] === 'string' && !obj[key].trim()) {
            delete obj[key];
        }
    });
    return obj;
}
