<template >
    <div class="menu-box">
        <a-menu @menu-item-click="menuChange" mode="horizontal" class="menu"
            :selected-keys="[router.currentRoute.value.fullPath]">
                <div class="logo-box">
                  <img  class="logo-img" src="../../assets/img/Neslora.png">
                </div>
            <div class="menu-btn-box">
                <a-select class="lang-select-box" v-model="lang" default-value="en" @change="handleSelect"
                    :style="{ width: '73px',height:'45px' ,borderRadius:'12px',backgroundColor:' #1C1C1C',border:'none'}" :trigger-props="{ autoFitPopupMinWidth: true }">
                    <template #label="{ data }">
                      <div style="padding-top: 5px"  >
                        <sphere theme="outline"   size="24" fill="#9E9E9E"/>
                      </div>
                    </template>
                    <a-option :value="'en'">English</a-option>
                    <a-option :value="'zh'">繁體中文</a-option>
                </a-select>

              <div  v-show="!getToken()"  class="btn">
                <a-button  class="button-background" type="primary" style="margin-right: 8px" size="large" @click="router.push('/login')">{{t('headNavigation.login')}}</a-button>
                <a-button class="reset-button"   size="large" @click="router.push('/registration')">{{t('headNavigation.registration')}}</a-button>
              </div>

              <span  v-show="getToken()"  class="user-name-text">{{userStore.userMsg.nickname}}</span>
              <a-avatar v-show="getToken()" class="avatar-box" trigger-type="button" :size="48" @click="router.push('/user')">
                            <img alt="avatar" src="../../assets/img/head.png" />
                   </a-avatar>
            </div>
          <span  class="menu-box-span">
         <a-menu-item class="menu-item" key="/index"  >
                <home  theme="outline" size="18" style=" vertical-align: sub;" fill="#ffffff" />
              </a-menu-item>
     <a-menu-item class="menu-item" key="/packagePurchase">{{
        t('headNavigation.menuItem.packagePurchase')
         }}</a-menu-item>
              <a-menu-item class="menu-item" key="/agentExtraction">{{
                  t('headNavigation.menuItem.agentExtraction')
                }}</a-menu-item>


          <a-menu-item class="menu-item" key="/user/product">
               {{
                    t('headNavigation.menuItem.useList')
                  }}
              </a-menu-item>
             <a-menu-item style=" margin-right: 0" class="menu-item" key="/contact">
                {{
                    t('headNavigation.menuItem.contact')
                  }}
              </a-menu-item>
          </span>
        </a-menu>

    </div>
</template>

<script setup>

import { ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import useUser from '@/store/modules/user'
import { useI18n } from 'vue-i18n'
const { t, locale } = useI18n()
import {getToken, removeSessionToken} from '@/uilts/user'
import { Notification } from '@arco-design/web-vue';
import { updateExitLogin } from "@/api/user/user";
const router = useRouter();
const route = useRoute()
const userStore = useUser()
import {Home,Sphere} from '@icon-park/vue-next';

import useLang from '@/store/modules/lang'
const langStore = useLang()
const lang = ref(langStore.currentLang)

function handleSelect(value) { //语言切换
    langStore.setLang(value)
}

watch(() => langStore.currentLang, (newLang) => {//监听语言切换
    locale.value = newLang
    const name = process.env.VUE_APP_NAME
    const title = route.meta[newLang]
    document.title = title ? `${title}-${name}` : name;
}
);

function menuChange(key) {
    router.push(key)

}

function exitLogin() { //退出登录
    updateExitLogin().then(r => {
        Notification.success({
            title: t('headNavigation.exit'),
        })
        router.replace('/login')
        userStore.removeUser()
        removeSessionToken()
    })
}

</script>

<style scoped lang="less">
.avatar-box {
    cursor: pointer;
    margin-left: 20px;
    margin-top: -10px;

}

.menu-box {
    position: fixed;
    width: 100%;
    z-index: 1000;
    top: 15px;
  .arco-menu-light.arco-menu-horizontal .arco-menu-item.arco-menu-selected,
  .arco-menu-light.arco-menu-horizontal .arco-menu-group-title.arco-menu-selected,
  .arco-menu-light.arco-menu-horizontal .arco-menu-pop-header.arco-menu-selected,
  .arco-menu-light.arco-menu-horizontal .arco-menu-inline-header.arco-menu-selected{
    border-radius: 8px 8px 8px 8px;
    background: linear-gradient( 124deg, #FFA800 0%, #FF9047 98%);
    color: #FFFFFF;
  }
  ::v-deep .arco-menu-selected-label{
    background-color: transparent;
    position: static; /* 取消之前的定位设置 */
    height:0;

  }

  .arco-menu-light .arco-menu-item, .arco-menu-light .arco-menu-group-title, .arco-menu-light .arco-menu-pop-header, .arco-menu-light .arco-menu-inline-header{
    background-color: transparent;
    color: white;
    font-size: 16px;
  }

}
.menu{
  text-align: center;
  background-color: transparent;
}


.logo-box {
    float: left;
    padding-left: 40px;
    .logo-img{

    }
}

.menu-box-span{
  padding: 20px 40px;
  background-color: #1C1C1C;
  border-radius: 12px 12px 12px 12px;
  //min-width: 850px;
  margin-left: 100px;

}

.menu-item {
  margin-right: 58px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.menu-btn-box {
  float: right;
  margin-top: -8px;
.user-name-text{
  display: inline-block;
  color: #DDDDDD;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  padding-left: 20px;
  width: 80px;
}
}
.btn{
  float: right;
  margin-left: 10px;
  .button-background{
    height: 43px;
  }
  .reset-button{
    height: 43px;
  }
}

@media screen and  (max-width: 1450px){
  .menu-box-span{
    display: none;
  }
}


</style>
