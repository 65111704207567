import { getToken, getUserLang } from '@/uilts/user'
export const whiteList = ['/contact','/agentExtraction','/index','/refund', '/service', '/privacy', '/registration', '/login', '/findPassword', '/packagePurchase'] //白名单
export const beforeEachGuard = (to, from, next) => {
    const title = to.meta[getUserLang()]
    const name = process.env.VUE_APP_NAME
    document.title = title ? `${title}-${name}` : name;
    if (whiteList.includes(to.path)) { //白名单
        next() //白名单直接放行
        return
    }
    if (getToken()) //登录
    {
        next()
    } else {
        next('/login')
    }
    window.scrollTo(0, 0);

};
