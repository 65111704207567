import { ref } from 'vue'
import { defineStore } from 'pinia'
import { selectUserMessage } from '@/api/user/user'

import { setSessionToken, getSessionToken } from '@/uilts/user'
export default defineStore("user", () => {
    const token = ref(getSessionToken())
    const userMsg = ref({})
    setUser()
    function setToken(accessToken) {
        token.value = `Bearer ${accessToken}`;
        setSessionToken(token.value)

    }
    function setUser() { //获取用户信息
        if (token.value == null) { return }
        selectUserMessage().then(r => {
            userMsg.value = r.data
        })
    }
    function updateUserMessage() //更新用户信息
    {
        selectUserMessage().then(r => {
            userMsg.value = r.data
        })
    }
    function removeUser() {
        token.value = null
        userMsg.value = {}
    }
    return {
        token,
        setToken,
        setUser,
        userMsg,
        removeUser,
        updateUserMessage
    }
})

