import { createRouter, createWebHistory } from "vue-router";
import Registration from '@/view/registration'
import { beforeEachGuard } from '@/router/permission'
const routes = [
    {
        path: '/',
        name: 'home',
        redirect: '/index',
        component: () => import('@/view/'),
        children: [
            {
                path: '/index',
                component: () => import('@/view/home/index.vue'),

            },
            {
                path: '/agentExtraction',
                component: () => import('@/view/extract'),
                meta: {
                    zh: '代理提取',
                    en: 'Proxy extraction'
                }
            },
            {
                path: '/packagePurchase/:id?',
                name: 'packagePurchase',
                component: () => import('@/view/packagePurchase'), meta: {
                    zh: '套餐購買',
                    en: 'Package purchase'
                }
            },
            {
                path: 'contact',
                name: 'contact',
                component: () => import('@/view/contact'),
                meta: {
                    zh: '聯繫我們',
                    en: 'contact us'
                }
            },
            {
                path: '/user',
                redirect: '/user/manage',
                component: () => import('@/view/user'),
                children: [
                    {
                        path: 'manage',
                        name: 'manage',
                        component: () => import('@/view/user/manage'),
                        meta: {
                            zh: '賬號管理',
                            en: 'account management'
                        }
                    },
                    {
                        path: 'order',
                        name: 'order',
                        component: () => import('@/view/user/order'),
                        meta: {
                            zh: '訂單管理',
                            en: 'Order management'
                        }
                    }, {
                        path: 'product',
                        name: 'product',
                        component: () => import('@/view/user/product'),
                        meta: {
                            zh: '使用列表',
                            en: 'Use List'
                        }
                    },
                    {
                        path: 'identity',
                        name: 'identity',
                        component: () => import('@/view/user/identity'),
                        meta: {
                            zh: '實名認證',
                            en: 'Real name authentication'
                        }
                    },

                ]
            },
        ]
    }, {
        path: '/login',
        name: 'login',
        component: () => import('@/view/login/'),
        meta: {
            zh: '用户登錄',
            en: 'User login'
        }
    },
    {
        path: '/registration',
        name: 'registration',
        component: Registration,

        meta: {
            zh: '註冊',
            en: 'register'
        }
    },
    {
        path: '/findPassword',
        name: 'findPassword',
        component: () => import('@/view/findPassword/'),

        meta: {
            zh: '忘記密碼',
            en: 'Forgot password'
        }
    },

    {
        path: '/userAgreement',
        name: 'userAgreement',
        component: () => import('@/view/agreement/userAgreement/index.vue'), meta: {
            zh: '用户協議',
            en: 'User Agreement'
        }
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/view/agreement/privacy/index.vue'), meta: {
            zh: '隱私政策',
            en: 'Privacy Policy'
        }
    },
    {
        path: '/service',
        name: 'service',
        component: () => import('@/view/agreement/service/index.vue'), meta: {
            zh: '服務條款',
            en: 'Service Terms'
        }
    },
    {
        path: '/refund',
        name: 'refund',
        component: () => import('@/view/agreement/refund/index.vue'), meta: {
            zh: '退款協議',
            en: 'Refund Agreement',
        }
    },
    {
        path: '/:pathMatch(.*)*', //404
        name: 'notFound',
        component: () => import('@/view/error/404'),
        meta: {
            zh: '404頁面丟失',
            en: '404 page lost'
        }
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach(beforeEachGuard);

export default router
