export default {
    headNavigation: { //顶部导航栏
        login: '登 录',
        title: '菜单',
        registration: '注 册',
        exit: '退出登錄成功',
        menuItem: { //菜单名称
            home: '首 頁',
            packagePurchase: '套 餐 購 買',
            agentExtraction: '代 理 提 取',
            contact: '聯 系 我 們',
            useList: '使 用 列 表'
        }
    }, home: { //主页
        titleItem:{
            t1:'Neslora目前銷售的產品是',
            t2:'海外原生獨享',
            t3:'家寬',
            t4:'住宅IP'
        },
        network:'雙ISP',
        efficient:'高效率的服務',
        purity:'高純淨值',
        title: '当前我們 Neslora出售的产品都為：原生独享家宽住宅IP，双ISP, 一級運營商，純淨度高。',
        tips: '特别提醒',
        careful1: '因為政策原因，Neslora提供的所有產品及服務在中國大陸不可用，不支持訪問中國大陸網站，感謝您的理解!',
        careful2: '由於是真正的家寬住宅，IP會偶尔出現變化，發生變化後我們會第一時間給您補上新的IP，購買前請仔細考虑评估，對所有給您造成的不便請及時聯系客服，請諒解!',
        privacy: '隱私政策',
        service: '服務條款',
        refund: '退款政策',
        price:'一個更好的價格',
        pure:'真正的家寬',
        customisation:'純凈獨享',
        available:'IP 可用性在99% 以上',
        sales:'高效的售後服務',
        preferential:'多套餐購買更優惠',
        viewAll:'查看全部',
        scenarios:'應用合作',
        scenariosItem:{
            jy:'剪映',
            jyTips:'抖音官方剪輯軟體，有電腦版/手機版/網頁版',
            tikTok:'TikTok下载器',
            tikTokTips:'免費下載無水印的無限量 TikTok 視頻',
            windowTool:'微軟配音工具',
            windowToolTips:'一款多功能的配音軟體,可以幫助用戶完成語音合成、語音識別、發聲和錄音等多項任務',
            pr:'Pr',
            prTips:'專業視頻剪輯工具',
            xf:'訊飛配音',
            xfTips:'以網際網路為連接的專業配音服務平臺，致力於為用戶提供合成配音+真人配音一站式配音服務新體驗',
            ajj:'愛剪輯',
            ajjTips:'超易用、強大的視頻剪輯軟體',
            scamalytics:'Scamalytics',
            scamalyticsTips:'IP潔凈度查詢，國外一個提供IP欺詐評分的網站。',
            iPinfo:'IPinfo',
            iPinfoTips:'IP种类查询，通过对IP信息的的挖掘, 发现滥用IP的行为以及各种类型的机器行为, 区分正常访问和非正常访问, 构建干净的互联网环境, 为站长或者系统管理员提供准确的业务决策。',

        },
        aboutUs:{
            name:'Neslora 引言',
            text:'Neslora旨在為廣大客戶提供最好的產品服務，專注於解決抖音短視頻運營中的各種問題如封號、視頻0播放、限流、多帳號關聯、起號難等等。請嚴格遵守當地法律法規允許使用的範圍。',
            about:'聯繫我們',
            title:'關於我們',
        }

    },
    bottomNavigation:{
        aboutTitle:'關於我們',
        contactTitle:'聯繫我們',
        nav:'快捷導航'

    },
    registration: { //注册模块  忘记密码
        nickname: '用户名',
        nicknameTip: '請輸入用户名',
        mobile: '手機號',
        mobileTip: '請輸入手機號',
        invite: '邀請碼',
        invitedTip: '請輸入邀請碼',
        title: '註冊',
        return: '返回',
        registration: '註冊',
        email: '郵箱',
        emailTip: '請輸入郵箱',
        password: '密碼',
        passowrdTip: '請輸入密碼',
        confirmPassword: '確認密碼',
        confirmPasswordTip: '請輸入確認密碼',
        phoneVerificationCode: '驗證碼',
        phoneError: '請填寫手機號',
        passwordError: '請填寫密碼',
        confirmPasswordError: '請填寫確認密碼',
        passowrdWarning: '兩次密碼不一致',
        phoneVerificationCodeError: '請填寫郵箱驗證碼',
        userCommitment: '《用户承诺书》',
        signUp: '已有賬號？',
        snedMessage: '發送驗證碼',
        formatError: '郵箱格式錯誤',
        codeSucess: '驗證碼發送成功',
        suess: '註冊成功',
        atypism: '兩次密碼不一致',
        forgotPassword: '忘記密碼',
        submit: '提交',
        modify: '修改密碼成功',
        newPasswordTips: '請輸入新密碼',
        confirmNewPasswordTips: '請輸入確認新密碼',
        registrationTips:'已有賬號？',
        sendTips:'發送驗證碼前請先填寫邀請碼',

    }, login: { //登录模块
        forgotPassword: '忘記密碼',
        registerNow: '註冊',
        noAccount: '沒有賬號？',
        emailTip: '請輸入郵箱',
        passowrdTip: '請輸入密碼',
        title: '登錄',
        loginSucess: '登錄成功',
        loginTips:'如果您沒有帳戶註冊，您可以',
        tips:'Neslora 旨在為我們的客戶提供最好的產品服務，請嚴格遵守當地法律法規允許使用的範圍。',
    },
    packagePurchase: {//套餐购买
        buy: '套餐購買',
        tips: '購買的IP餘額可以用來提取新的IP，也可以對已提取使用的IP進行續期。',
        region: '地區',
        regionTitle: '提示',
        ipNumber: 'IP數量',
        purchaseDuration: '購買時長',
        orderPrice: '訂單價格',
        day: '天',
        buyNow: '立即購買',
        customized: '定製套餐',
        customizedTips: '更多套餐,請聯繫客服定製',
        contact: '聯繫客服',
        experience: '免費體驗',
        regionTips: '此爲地區分佈展示，購買套餐後代理提取裏提取',
        regionTips1: '此爲地區分佈展示，請購買套餐後前往',
        regionTips2: '中提取',
        number: '數量',
        paySucess: '購買成功',
        paySucessTips: '套餐購買成功，可前往',
        paySucessTips1: '裏挑選IP並提取',
        confirm: '確認',
        receive: '警告',
        receiveTips: '請到"代理提取"頁面提取試用。',
        unit:'個',
        userRights:'妳已經購買過一次，該套餐只能購買一次！',
        purchaseTips:'該套餐只能購買一次',
        example:'我們的IP示例',


    }, agentExtraction: {
        title: '長效代理',
        tips: '您可以根據國家，州，城市進行篩選後選擇最終符合您需求的產品。',
        country: '國家',
        state: '州',
        city: '城市',
        stateTips: '請選擇州',
        balance: 'IP餘額',
        region: '地區',
        operation: '操作',
        extract: '提取',
        search: '搜索',
        reset: '重置',
        countryTips: '請選擇國家',
        regionTips: '請選擇地區',
        cityTips: '請選擇城市',
        agent: '提取代理',
        agentTips1: '提取扣除1個餘額，提取的IP請在',
        agentTips2: '中查看並使用',
        menu: '使用列表',
        extractionSucess: '提取成功',
        batchExtraction: '批量提取IP',
        extractionQuantity: '提取數量',
        confirmExtractionQuantity: '確認提取信息',
        confirmExtraction: '確認提取',
        extractionQuantityTips: '請輸入提取數量',
        selectAreaTips: '未選擇，將隨機分配',
        confirmNumberTips: '是否確認提取請覈對提取信息',
        batchTips:'批量提取的IP請在',

    }, contact: {
        title: '我們的聯繫方式',
        mailbox: '郵箱',
        telegram: '電報',
        copySucess: '複製成功'
    },
    user: { //个人中心导航
        menu: {
            management: '賬號管理',
            order: '訂單管理',
            product: '使用列表',
            authentication: '实名认证',
            exitLogin: '退出登錄',
            exitLoginTips:'確定要退出登錄嗎？',
            exitConfirm:'確認退出',

        },
        userMessage: {
            user: '用户',
            mailbox: '郵箱',
            balance: 'IP餘額',
            recharge: '充值',
            changePassword: '修改密碼',
            confirmModification: '確認修改',
            oldPassword: '舊密碼',
            newPassowrd: '新密碼',
            confirmPassword: '確認新密碼',
            oldPassowrdTips: '請輸入舊密碼',
            newPassowrdTips: '請輸入新密碼',
            confirmPassowrdTips: '請輸入確認新密碼',
            sucess: '密碼修改成功',
            atypism: '兩次密碼不一致',
            empty:'更多新產品正在研發進行中...',
            tips1:'邀請好友可得',
            tips2:'的邀請佣金',
            tips3:'被邀請人今後的每筆訂單都將計入邀請人的累計金額',
            promotion: {  //好友邀请
                cardTitle: '我的推廣',
                title: '推廣邀請',
                registrationStatus: '註冊狀態',
                registrationStatusTips: '請選擇註冊狀態',
                search: '搜索',
                reset: '重置',
                registered: '未註冊',
                completeRegistration: '已註冊',
                unregistered: '未註冊，可邀請',
                invitationCode: '邀請碼',
                user: '註冊用戶',
                createTime: '創建時間',
                updateTime: '更新时间',
                host: '推廣主',
                totalCommission: '累計總佣金',
                settlementCommission: '已結算佣金',
                settableCommission: '可結算佣金'
            }, commission: {
                title: '推廣擁金',
                search: '搜索',
                reset: '重置',
                user: '購買用戶',
                userTips: '請輸入購買用戶',
                purchaseAmount: '購買金額',
                commissionRate: '佣金率',
                settlement: '提成/結算',
                balance: '餘額',
                createTime: '創建時間',
                updateTime: '更新时间',
            }, settlement: {
                title: '佣金結算',
                tips: '如果您需要結算佣金，請聯繫我們獲取詳細的指導和幫助。',
                contact: '聯繫我們',
                tips1: '結算請'
            }

        }, order: {
            orderNumber: '訂單編號',
            orderNumberTips: '請輸入訂單號',
            packageType: '套餐類型',
            ipNumber: 'IP個數',
            purchaseDuration: '購買時長(天)',
            orderPrice: '訂單價格',
            orderTime: '下單時間',
            home: '首頁',
            title: '訂單管理',
            startTime: '開始時間',
            endTime: '結束時間',
            search: '搜索',
            reset: '重置',
            packageType: '套餐類型',
            ipNumber: 'IP個數',
        }, product: {
            notice: '公告',
            ipTips: '請輸入IP',
            tips: '由於Neslora家寬住宅的真實屬性決定導致了IP會偶爾出現變化。當您發現IP連不上的時候請及時聯繫我們，我們給您立馬免費替換同一城市的新IP，對您造成的麻煩與不便還請諒解。',
            search: '搜索',
            reset: '重置',
            home: '首頁',
            title: '使用列表',
            extractionTime: '提取時間',
            expirationDate: '到期時間',
            ipAddress: 'IP地址',
            countryTips: '請選擇國家',
            regionTips: '請選擇地區',
            city: '城市',
            cityTips: '請選擇城市',
            country: '國家',
            region: '地區(州)',
            purchaseStatus: '購買狀態',
            purchaseStatusTips: ' 請選擇購買狀態',
            overdue: '過期',
            normal: '正常',
            soon: '將過期(三天過期)',
            ipAddress: 'IP地址',
            daysRemaining: '剩餘天數',
            abnormal: '異常',
            proxyStatus: '代理狀態',
            operation: '操作',
            day: '天',
            use: '使用',
            details: '詳情',
            renewal: '續期',
            copySucess: '複製成功',
            copy: '複製',
            ipMessage: 'IP信息',
            port: '端口',
            account: '賬號',
            password: '密碼',
            oNekey: '一鍵複製',
            time: '時間',
            extract: '提取',
            surplus: '剩餘',
            useTitle: '用户名: 密碼 認證信息',
            format: '設置格式',
            command: '命令',
            balance: 'IP餘額',
            renewalTips: '續期將扣除1個IP餘額',
            renewalSucess: '續期成功',
            export: '導出',
            replaceIp: '替換IP',
            batchRenewal: '批量續期',
            batchRenewalTips: '是否對已選代理進行續期,確認無誤後請點擊確認進行續期？',

        }

    }

}
