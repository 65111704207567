import axios from 'axios';
import { Notification } from '@arco-design/web-vue';
import { getToken, removeLoginState } from '@/uilts/user';
import router from '@/router'
import { whiteList } from '@/router/permission'

// 创建 Axios 实例
const instance = axios.create({
    baseURL: process.env.VUE_APP_API
});

// 请求拦截器
instance.interceptors.request.use(
    config => {
        const token = getToken()
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        const e = error.response ? error.response.data : null;
        if (e == null) {
            expire()
        }
        switch (e.code) {
            case 'A0210': //账号密码错误
                wang(e.msg)
                break;
            case 'A0230': //token无效或已过期
                expire()
                break;
            default:
                wang(e.msg)
                break;
        }

        return Promise.reject(error);
    }

);


//弹出警告
function wang(msg) {
    Notification.warning({
        title: '提示',
        content: msg,
    });
}

function expire() { //登录过期
    removeLoginState()
    const route = router.currentRoute.value.path
    if (!whiteList.includes(route.path)) {
        router.push('/login')
    }
}

export default instance;
