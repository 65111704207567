export default {
    headNavigation: { //顶部导航栏
        login: 'Log On',
        registration: 'Registration',
        title: 'menu',
        exit: 'Login successfully logged out',
        menuItem: { //菜单名称
            home: 'Home',
            packagePurchase: 'Package Purchase',
            agentExtraction: 'Proxy Extraction',
            contact: 'Contact Us',
            useList: 'Use List'
        }
    }, home: { //主页
        titleItem:{
            t1:'The products currently ',
            t2:'sold by ',
            t3:'Nesora are native exclusive home wide ',
            t4:'residential Ip'
        },
        network:'Dual ISP',
        efficient:'Efficient service',
        purity:'High Purity',
        title: 'The products currently sold by Neslora are native exclusive home wide residential IP, dual ISP, first tier operator, high purity.',
        tips: 'Special Reminder',
        careful1: 'Due to policy reasons, all products and services provided by Nestora are not available in Chinese Mainland, and access to Chinese Mainland websites is not supported. Thank you for your understanding!',
        careful2: 'As it is a genuine residential property, the IP address may occasionally change. Once a change occurs, we will promptly provide you with a new IP address. Please carefully consider and evaluate before purchasing. For any inconvenience caused, please contact customer service in a timely manner. We apologize for any inconvenience caused!',
        privacy: 'Privacy Policy',
        service: 'Service Terms',

        refund: 'Refund Policy',
        price:'A better price',
        pure:'True home width',
        customisation:'Pure and Exclusive',
        available:'IP availability is over 99%',
        sales:'Efficient after-sales service',
        preferential:'Multiple package purchases offer better discounts',
        viewAll:'View All',
        scenarios:'Application cooperation',
        scenariosItem:{
            jy:'Jianying',
            jyTips:'Tiktok official editing software, including computer version/mobile version/web version',
            tikTok:'TikTok Downloader',
            tikTokTips:'Free download of unlimited TikTok videos without watermarks',
            windowTool:'Microsoft voice over tool',
            windowToolTips:'A multifunctional dubbing software that can help users complete multiple tasks such as speech synthesis, speech recognition, vocalization, and recording',
            pr:'Pr',
            prTips:'Professional video editing tool',
            xf:'IFlytek voice acting',
            xfTips:'A professional dubbing service platform connected by the Internet, dedicated to providing users with a one-stop dubbing service experience of composite dubbing and live dubbing',
            ajj:'Love editing',
            ajjTips:'Ultra user-friendly and powerful video editing software',
            scamalytics:'Scamalytics',
            scamalyticsTips:'IP cleanliness query, a website abroad that provides IP fraud ratings.',
            iPinfo:'IPinfo',
            iPinfoTips:'IP type query, through the mining of IP information, finds the behavior of IP abuse and various types of machine behavior, distinguishes between normal access and abnormal access, builds a clean Internet environment, and provides accurate business decisions for the webmaster or system administrator.',
        },
        aboutUs:{
            name:'Neslora Introduction',
            text:'Neslora aims to provide our customers with the best product service,Focus on solving various problems in TikTok short video operation,such as blocking, video 0 playback, stream limiting, multi-account association, and difficulty in starting accounts.please strictly comply with the scope of local laws and regulations permitted use.',
            about:'Contact Us',
            title:'About us'
        }


    },  bottomNavigation:{
        aboutTitle:'About us',
        contactTitle:'Contact us',
        nav:'Quick Navigation'
    }, registration: { //注册模块
        nickname: 'User nickname',
        nicknameTip: 'Please one user name',
        mobile: 'cell-phone number',
        mobileTip: 'Please enter your phone number',
        invite: 'Invitation code',
        invitedTip: 'Please enter the invitation code',
        subHeadings: 'Join IPYAYA, enjoy million pure agent',
        title: 'Registration',
        return: 'Return',
        registration: 'Registration',
        email: 'Email',
        emailTip: 'Please enter your email',
        password: 'Password',
        passowrdTip: 'Please enter your password',
        confirmPassword: 'Confirm the password',
        confirmPasswordTip: 'Please enter your confirmation password',
        phoneVerificationCode: 'Verification Code',
        phoneError: 'Please fill in your mobile number',
        passwordError: 'Please fill in the password',
        confirmPasswordError: 'Please fill in the confirmation code',
        passowrdWarning: 'The two passwords do not match',
        phoneVerificationCodeError: 'Please fill in the verification code',
        agreement: 'I have read and agreed',
        userAgreement: '《Meiyaproxy user agreement》',
        userCommitment: '《User commitment》',
        signUp: 'An account number?',
        snedMessage: 'Send the verification code',
        formatError: 'Email format error',
        codeSucess: 'Verification code sent successfully',
        suess: 'registered successfully',
        atypism: 'Two passwords are inconsistent',
        forgotPassword: 'Forgot password',
        submit: 'Submit',
        modify: 'Password changed successfully',
        newPasswordTips: 'Please enter a new password',
        confirmNewPasswordTips: 'Please enter the confirmation new password',
        registrationTips:'An account number?',
        sendTips:'Please fill in the invitation code before sending the verification code',


    }, login: { //登录模块
        forgotPassword: 'Forgot password',
        registerNow: 'register',
        noAccount: 'Don t have an account?',
        emailTip: 'Please enter your email address',
        passowrdTip: 'Please enter a password',
        title: 'Sign in',
        loginSucess: 'Login successful',
        loginTips:'If you don’t have an account register You can',
        tips:'Neslora aims to provide our customers with the best product service, please strictly comply with the scope of local laws and regulations permitted use.',

    }, packagePurchase: {
        buy: 'Package purchase',
        tips: 'The balance of the purchased IP can be used to extract new IPs, or to renew the IPs that have already been extracted and used.',
        region: 'Region',
        regionTitle: 'Prompt',
        ipNumber: 'Number of IPs',
        purchaseDuration: 'Purchase duration',
        orderPrice: 'Order price',
        day: 'day',
        buyNow: 'Buy Now',
        customized: 'Customized package',
        customizedTips: 'For more packages, please contact customer service for customization',
        contact: 'Contact customer service',
        experience: 'Free trial ',
        regionTips: 'Currently not available for selection. After purchasing the package, the agent will extract it from the collection',
        number: 'quantity',
        regionTips1: 'This is a display of regional distribution, please purchase the package and go, please purchase the package and go',
        regionTips2: 'Extract from the middle',
        paySucess: 'Purchase successful',
        paySucessTips: 'Package purchase successful, you can go to',
        paySucessTips1: 'Select IP and extract',
        confirm: 'Confirm',
        receive: 'Warning',
        receiveTips: 'Please go to the Proxy Extraction page to extract the trial.',
        unit:'Of the project',
        userRights:'You ve already bought it once. You can only buy it once!' ,
        example:'Our IP Example',
        purchaseTips:'Can only be purchased once',

    }, agentExtraction: {
        title: 'Long term agent',
        tips: 'You can filter by country, state, and city to select the final product that meets your needs.',
        country: 'Country',
        state: 'State',
        balance: 'IP balance',
        region: 'Region',
        stateTips: 'Please select a state',
        city: 'City',
        operation: 'Operation',
        extract: 'Extract',
        search: 'Search',
        reset: 'Reset',
        countryTips: 'Please select a country',
        regionTips: 'Please select region',
        cityTips: 'Please select a city',
        agent: 'Extract agent',
        agentTips1: 'Withdraw and deduct 1 balance, please retrieve the IP address in',
        agentTips2: 'View and use in the middle',
        menu: 'Using a proxy',
        extractionSucess: 'Extraction successful',
        batchExtraction: 'Batch extraction of IP',
        extractionQuantity: 'Extraction quantity',
        confirmExtractionQuantity: 'Confirm extraction information',
        confirmExtraction: 'Confirm extraction',
        extractionQuantityTips: 'Please enter the extraction quantity',
        selectAreaTips: 'Not selected, will be randomly assigned',
        confirmNumberTips: 'Are you sure about the extraction? Please verify the extraction information',
        batchTips:'Please extract the IP addresses in bulk',
    }, contact: {
        title: 'Our contact information',
        mailbox: 'Email',
        telegram: 'Telegram',
        copySucess: 'Copy successful'
    }, user: {
        menu: {
            management: 'Account Manage',
            order: 'Order Manage',
            product: 'Use List',
            authentication: 'authentication',
            exitLogin: 'Logout',
            exitLoginTips:'Are you sure you want to log out?',
            exitConfirm:'Confirm exit',
        },
        userMessage: {
            user: 'User',
            mailbox: 'Mailbox',
            balance: 'IP balance',
            recharge: 'Recharge',
            changePassword: 'Change Password',
            confirmModification: 'Confirm modification',
            oldPassword: 'Old password',
            newPassowrd: 'New password',
            confirmPassword: 'Confirm password',
            newPassowrdTips: 'Please enter a new password',
            oldPassowrdTips: 'Please enter old password',
            confirmPassowrdTips: 'Please enter the confirmation new password',
            sucess: 'Password changed successfully',
            atypism: 'Two passwords are inconsistent',
            empty:'More new products are currently under development...',
            tips1:'Inviting friends can earn',
            tips2:'Invitation commission',
            tips3:'Each order placed by the invitee in the future will be counted towards the invitee s cumulative amount',
            promotion: {
                cardTitle: 'My Promotion',
                completeRegistration: 'Registered',
                title: 'Promotion invitation',
                registrationStatus: 'Registration status',
                registrationStatusTips: 'Please select the registration status',
                search: 'Search',
                reset: 'Reset',
                registered: 'Unregistered',
                unregistered: 'Unregistered, can invite',
                invitationCode: 'Invitation code',
                user: 'Registered User',
                createTime: 'Creation Time',
                updateTime: 'Update Time',
                host: 'Promotion Master',
                totalCommission: 'Accumulated total commission',
                settlementCommission: 'Commission settled',
                settableCommission: 'Settable commission'
            },
            commission: {
                title: 'Promote Gold Support',
                search: 'Search',
                reset: 'Reset',
                user: 'Purchase Users',
                userTips: 'Please enter the purchasing user',
                purchaseAmount: 'Purchase amount',
                commissionRate: 'Commission Rate',
                settlement: 'Commission/Settlement',
                balance: 'Balance',
                createTime: 'Creation Time',
                updateTime: 'Update Time',
            }, settlement: {
                title: 'Commission settlement',
                tips: 'If you need to settle commissions, please contact us for detailed guidance and assistance.',
                contact: 'Contact Us',
                tips: 'If you need to settle commissions, please contact us for detailed guidance and assistance',
                tips1: 'Please settle'
            }

        }, order: {
            orderNumber: 'Order Number',
            orderNumberTips: 'Please enter the order number',
            packageType: 'Package type',
            ipNumber: 'Number of IPs',
            purchaseDuration: 'Purchase duration (days)',
            orderPrice: 'Order price',
            orderTime: 'Order time ',
            home: 'Home',
            title: 'Order management',
            startTime: 'start time',
            endTime: 'End time',
            search: 'Search',
            reset: 'Reset',
            packageType: 'Package type',
            ipNumber: 'Number of IPs',
        },
        product: {  //使用列表
            notice: 'Notice',
            ipTips: 'Please enter IP address',
            tips: 'Due to the true nature of Neslora s spacious residence, the IP may occasionally change.When you find that the IP cannot be connected, please contact us in a timely manner.We will immediately replace the new IP in the same city for free.We apologize for any inconvenience caused to you.',
            search: 'Search',
            reset: 'Reset',
            home: 'Home',
            title: 'Use List',
            extractionTime: 'Extraction Time',
            expirationDate: 'Expiration Date',
            ipAddress: 'IP address',
            countryTips: 'Please select a country',
            regionTips: 'Please select region',
            city: 'City',
            cityTips: 'Please select a city',
            country: 'Country',
            region: 'Region (State)',
            purchaseStatus: 'Purchase status',
            purchaseStatusTips: ' Please select the purchase status',
            overdue: 'be overdue',
            normal: 'Normal',
            soon: 'Will expire (within three days)',
            abnormal: 'Abnormal',
            ipAddress: 'IP address',
            daysRemaining: 'Days Remaining ',

            proxyStatus: 'Proxy Status',
            operation: 'Operation',
            day: 'Day',
            use: 'Use',
            details: 'Details',
            renewal: 'Renewal',
            copySucess: 'Copy successful',
            copy: 'copy',
            ipMessage: 'IP information',
            port: 'Port',
            account: 'Account Number',
            password: 'Password',
            oNekey: 'One click copying',
            time: 'Time',
            extract: 'extract',
            surplus: 'surplus',
            useTitle: 'Username: Password Authentication Information',
            format: 'Format settings',
            command: 'command',
            balance: 'IP balance',
            renewalTips: 'Renewal will deduct 1 IP balance',
            renewalSucess: 'Renewal successful',
            export: 'Export',
            replaceIp: 'Alternate IP',
            batchRenewal: 'Batch renewal',
            batchRenewalTips: 'Do you want to renew the selected agent? After confirming that it is correct, please click "Confirm" to proceed with the renewal?',
        }

    }

}
