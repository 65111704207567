import { createApp } from 'vue'
import ArcoVue from '@arco-design/web-vue';
import App from './App.vue';
import '@arco-design/web-vue/dist/arco.css';
// 引入图标库
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import '@/assets/css/style.css'
//全局样式
import lang from '@/lang/index.js'
//语言包
import router from './router'
//路由组件
import 'animate.css'
//动画库
import pinia from '@/store/index.js'
//Pinia数据共享
import '@/assets/font/font.css'
//全局样式
import { Message } from '@arco-design/web-vue';
//全局提示
import '@arco-themes/vue-chengsezhuti/theme.less';
//主题库



const app = createApp(App);
Message._context = app._context;
app.use(ArcoVue);
app.use(lang)
app.use(ArcoVueIcon);
app.use(router)
app.use(pinia)
app.mount('#app');