export function formDataConvert(jsonData) {
    const formData = new FormData()
    for (let key in jsonData) {
        formData.append(key, jsonData[key]);
    }
    return formData
}

export function isNumber(number) {//验证是否数字 大于0 整数
    const regex = /^[1-9]\d*$/;
    return regex.test(number);
}
